/** @jsxImportSource @emotion/react */
import {
  styled,
  Box,
  TextField,
  Typography,
  TextFieldProps,
  TypographyProps,
  SxProps,
} from '@mui/material';
import { FC, ReactNode, ChangeEventHandler, useRef, useEffect } from 'react';
import { TEXT_COLOR } from '../../../typings';
import { VariableOption } from './variable-option';
import { InputBaseProps } from '@mui/material/InputBase';
import { css } from '@emotion/react';

export interface TextProps {
  headerTitle?: ReactNode;
  subtitle?: string;
  tags?: { name: string; id: string }[];
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onValueChange?: (val: string) => void;
  multiLine?: boolean;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  error?: boolean;
  sx?: SxProps;
  autoComplete?: string;
  textInputProps?: TextFieldProps;
  headerProps?: TypographyProps<'span'>;
  disabled?: boolean;
  autoFocus?: boolean;
  handleFetchSampleValue?: (id: string, name: string) => void;
  isBroadcast?: boolean;
  endAdornment?: InputBaseProps['endAdornment'];
  variant?: 'standard' | 'filled' | 'outlined';
  label?: string;
}

const StyledTextField = styled(TextField)(() => ({
  'input::placeholder, textArea::placeholder': {
    color: '#CDCDDF',
  },
  '.MuiInputBase-root::before': {
    borderBottom: '1px solid #E5E6EC',
  },
}));

const QSTextInput: FC<TextProps> = ({
  headerTitle,
  subtitle,
  tags,
  onChange,
  placeholder,
  autoFocus,
  onValueChange,
  multiLine,
  autoComplete,
  defaultValue,
  value,
  sx,
  textInputProps,
  headerProps,
  error = false,
  disabled = false,
  handleFetchSampleValue,
  isBroadcast,
  endAdornment,
  variant = 'standard',
  label,
}) => {
  const textInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocus && typeof textInputRef.current?.focus === 'function') {
      textInputRef.current?.focus();
    }
  }, [autoFocus]);

  return (
    <Box sx={sx}>
      {headerTitle && (
        <Typography
          {...headerProps}
          sx={{
            fontSize: '14px',
            fontWeight: '400',
            color: TEXT_COLOR,
            ...headerProps?.sx,
          }}
        >
          {headerTitle}
        </Typography>
      )}

      {subtitle && (
        <Typography
          sx={{
            color: TEXT_COLOR,
            opacity: '60%',
            fontSize: '12px',
            fontWeight: '400',
            marginTop: '12px',
          }}
        >
          {subtitle}
        </Typography>
      )}
      {label ? (
        <label
          css={css`
            color: #7a7a7a;
            font-size: 12px;
            display: block;
            margin-bottom: 6px;
            font-weight: 600;
          `}
        >
          {label}
        </label>
      ) : null}
      <StyledTextField
        hiddenLabel={true}
        fullWidth={true}
        placeholder={placeholder}
        variant={variant}
        multiline={multiLine}
        autoFocus={autoFocus}
        minRows={4}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        inputRef={textInputRef}
        autoComplete={autoComplete}
        disabled={disabled}
        error={error}
        size={variant == 'outlined' ? 'small' : 'medium'}
        sx={{
          ...(headerTitle || subtitle
            ? {
                marginTop: '12px',
              }
            : {}),
        }}
        InputProps={{
          endAdornment,
        }}
        {...textInputProps}
      />

      {tags && (
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',

            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {tags?.map(({ name, id }) => {
            return VariableOption({
              name,
              id,
              textInputRef,
              onClick: onValueChange,
              isBroadcast,
              handleFetchSampleValue,
            });
          })}
        </Box>
      )}
    </Box>
  );
};

export default QSTextInput;
