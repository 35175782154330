import { ChatFilterSlaStatus, ChatListAtom, ChatStatuses } from '../typings';
import { deserializeTagsFromQueryParams } from './deserialize-tags';

export const getDefaultChatFilters = (): ChatListAtom => {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location?.search);
  const userId = queryParams.get('assignedUserId') ?? '';
  const slaStatus =
    (queryParams.get('slaStatus') as ChatFilterSlaStatus) ?? 'non-breached';
  const status =
    (queryParams.get('status') as ChatStatuses) ?? ChatStatuses.ALL;
  const tags = deserializeTagsFromQueryParams(queryParams)
  const waba = queryParams.has('waba')
    ? (queryParams.get('waba') as string).split(',').filter(Boolean)
    : [];
  return {
    searchTerm: '',
    isSearchActive: false,
    assignedUserId: userId,
    tags,
    status,
    customList: {} as { [key: string]: string },
    leadCustomFieldIdList: undefined,
    currentCustomListId: '',
    waba,
    slaStatus,
  };
};
