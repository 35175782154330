/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import deepmerge from 'deepmerge';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {
  MobileDateTimePicker as DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { QSButton, QSButtonBase, QSTypography } from '../../atoms';
import Logo from '../../../assets/logo';
import { BORDER_COLOR, HEADER_HEIGHT } from '../../../typings';
import {
  deleteMessagingToken,
  firebaseAuth,
} from '../../../../infra/firebase/init';
import QSModal from '../../molecules/modal';
import { useCustomNavigate } from '../../../hooks/use-conversation-navigate';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { LoginStep, showLogoutModalAtom } from '../../../states/login';
import { trackLogout } from '../../../../infra/analytics/utils';
import { useSetProfile } from '../../../hooks/use-profile';
import { LoginStepAtom } from '../../../../modules/login/v1/state';
import { ROUTES } from '../../../constants/routes';
import CheckIcon from '@mui/icons-material/Check';
import SchoolIcon from '../../../icons/SchoolIcon';
import { getTrainingLink } from '../../../requests/organizations';
import useQuery from '../../../hooks/useQuery';
import { trainingLinkAtom } from '../../../states/training-link';
import {
  DEFAULT_TEAM,
  DT_ROADMAP_LINK,
  INACTIVE_STATUS_TIMER_SECONDS,
} from '../../../constants';
import { WabaIntegrationMemberStatus } from '../../../typings/waba';
import {
  getTeamMemberAvailabilityIcon,
  getTeamMemberStatusDisplayLabel,
  getTeamMemberStatusIcon,
} from '../../../utils/team-member-status';
import ConfirmationModal from '../../atoms/confirmation-modal';
import WelcomeBackIcon from '../../../icons/WelcomeBack';
import UpdateStatusIcon from '../../../icons/UpdateStatus';
import { getUserStatus, setUserStatus } from '../../../requests/user';
import useNotifications from '../../../hooks/use-notifications';
import { USER_STATUS_STORAGE_KEYS } from '../../../constants/storage';
import DropdownComponent, { DropdownProps } from '../../../modules/dropdown';
import { chatSettingsDropdownProps } from '../../../../modules/home/v1/constants';
import { UserStatusData } from '../../../typings/user';
import { userStatusAtom } from '../../../states/user';
import capitalizeFirstLetter from '../../../utils/capitalize-first-letter';
import { postMessageToIframe } from '../../../utils/post-message';
import Loader from '../../molecules/loader';
import ErrorIcon from '../../../icons/ErrorIcon';
import { walletDetailsAtom } from '../../../states/wallet';
import { openPopup } from '../../../utils/popup';
import requestConfig from '../../../config/request';
import { getFirebaseToken } from '../../../../infra/auth/firebase-auth';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReactComponent as RequestFeatureIcon } from '../../../../shared/icons/Bulb.svg';
import { getUserChannelData } from '../../../../modules/login/v1/utility';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export type TrainingLinkDataType = {
  show: boolean;
  link: string;
};

const Header = () => {
  const [open, setOpen] = useAtom(showLogoutModalAtom);
  const setUserProfile = useSetProfile();
  const setLoginStep = useSetAtom(LoginStepAtom);

  const navigate = useCustomNavigate();
  const { addNotification } = useNotifications();

  const [dateTimePickerOpen, setDateTimePickerOpen] = useState(false);
  const [selectStatusModalOpen, setSelectStatusModalOpen] = useState(false);
  const [selectStatusExpirationModalOpen, setSelectStatusExpirationModalOpen] =
    useState(false);
  const [isOnlineStatusConfirmModalOpen, setIsOnlineStatusConfirmModalOpen] =
    useState(false);
  const [isOfflineStatusConfirmModalOpen, setIsOfflineStatusConfirmModalOpen] =
    useState(false);

  const shouldShowWelcomeBackUserStatus = useCallback(() => {
    const lastOfflineSyncedTime = localStorage.getItem(
      USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME
    );
    if (
      typeof lastOfflineSyncedTime === 'string' &&
      lastOfflineSyncedTime.length > 0 &&
      dayjs(lastOfflineSyncedTime).isValid()
    ) {
      if (
        dayjs(new Date()).diff(dayjs(lastOfflineSyncedTime), 'seconds') >=
        INACTIVE_STATUS_TIMER_SECONDS
      ) {
        return true;
      }
    }
    return false;
  }, []);

  const {
    requestData: { data: trainingLinkData, loading: trainingLinkLoading },
  } = useQuery<TrainingLinkDataType>({
    requestAtom: trainingLinkAtom,
    queryFunction: getTrainingLink,
  });

  const userStatusGetter = useCallback(async () => {
    const userStatusData = await getUserStatus();
    if (
      shouldShowWelcomeBackUserStatus() &&
      typeof userStatusData?.status === 'string' &&
      userStatusData?.status.length > 0 &&
      userStatusData?.status !== WabaIntegrationMemberStatus.ACTIVE
    ) {
      setIsOnlineStatusConfirmModalOpen(true);
    }
    return userStatusData;
  }, [setIsOnlineStatusConfirmModalOpen]);

  const {
    requestData: { data: userStatusData, loading: userStatusLoading },
  } = useQuery<UserStatusData>({
    requestAtom: userStatusAtom,
    queryFunction: userStatusGetter,
  });

  const setUserStatusData = useSetAtom(userStatusAtom);

  const [teamMemberStatus, setTeamMemberStatus] = useState<{
    status: WabaIntegrationMemberStatus | null;
    statusExpiration: string | null;
  }>({
    status: userStatusData?.status ?? null,
    statusExpiration: userStatusData?.statusExpiration ?? null,
  });

  useEffect(() => {
    setTeamMemberStatus((prev) => ({
      ...prev,
      status: userStatusData?.status ?? null,
      statusExpiration: userStatusData?.statusExpiration ?? null,
    }));
  }, [userStatusData?.status, userStatusData?.statusExpiration]);

  const channelData = getUserChannelData();
  const hideDTDetails = !!channelData?.name;

  const startUserStatusTimer = useCallback(() => {
    localStorage.setItem(
      USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME,
      new Date().toISOString()
    );
  }, []);

  const stopUserStatusTimer = useCallback(() => {
    if (
      shouldShowWelcomeBackUserStatus() &&
      typeof userStatusData?.status === 'string' &&
      userStatusData?.status.length > 0 &&
      userStatusData?.status !== WabaIntegrationMemberStatus.ACTIVE
    ) {
      setIsOfflineStatusConfirmModalOpen(true);
    }
    localStorage.removeItem(USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME);
  }, [userStatusData?.status]);

  useEffect(() => {
    window.addEventListener('blur', startUserStatusTimer);
    return () => {
      window.removeEventListener('blur', startUserStatusTimer);
    };
  }, [startUserStatusTimer]);

  useEffect(() => {
    window.addEventListener('focus', stopUserStatusTimer);
    return () => {
      window.removeEventListener('focus', stopUserStatusTimer);
    };
  }, [stopUserStatusTimer]);

  const openDateTimePicker = () => {
    setDateTimePickerOpen(true);
  };

  const closeDateTimePicker = () => {
    setDateTimePickerOpen(false);
  };

  const { loading: walletLoading, data: walletData } =
    useAtomValue(walletDetailsAtom);

  const showLowBalanceBanner = useMemo(() => {
    if (!walletData || walletLoading) return false;
    switch (walletData.currencyCode) {
      case 'INR':
        return walletData.balance < 100;
      case 'USD':
        return walletData.balance < 10;
      case 'AED':
        return walletData.balance < 20;
      default:
        return walletData.balance < 10;
    }
  }, [walletData, walletLoading]);

  const handleOnClickRecharge = async () => {
    if (!walletData || walletLoading) return;
    if (walletData.generatePaymentLink) {
      const token = await getFirebaseToken();

      openPopup(
        `${requestConfig.dtCallbackUrl}/wallet?token=${token}`,
        '_self'
      );
    } else window.open(walletData.rechargeLink, '_blank');
  };

  const onStatusChange = async (status: WabaIntegrationMemberStatus | null) => {
    setUserStatusData((prev) => ({ ...prev, loading: true }));
    try {
      let updatedStatusExpiration: string | null =
        userStatusData?.statusExpiration ?? null;
      if (
        [
          WabaIntegrationMemberStatus.ACTIVE,
          WabaIntegrationMemberStatus.OFFLINE,
        ].indexOf(status as WabaIntegrationMemberStatus) > -1
      ) {
        updatedStatusExpiration = null;
      }
      await setUserStatus({
        status,
        statusExpiration: updatedStatusExpiration,
        teamId: userStatusData?.teamId as string,
      });
      setUserStatusData((prev) => ({
        ...prev,
        data: {
          ...(prev.data as UserStatusData),
          status,
          statusExpiration: updatedStatusExpiration,
        },
      }));
      addNotification({
        message: 'Your status has been updated',
        type: 'success',
      });
      postMessageToIframe(
        'profile',
        JSON.stringify({
          type: 'SET_USER_STATUS',
          data: { status, statusExpiration: updatedStatusExpiration },
        })
      );
    } catch (error) {
      addNotification({
        message: 'Something went wrong. Your status could not be updated',
        type: 'error',
      });
    } finally {
      setSelectStatusModalOpen(false);
      setSelectStatusExpirationModalOpen(false);
      setIsOnlineStatusConfirmModalOpen(false);
      setIsOfflineStatusConfirmModalOpen(false);
      setUserStatusData((prev) => ({ ...prev, loading: false }));
    }
  };

  const onStatusExpirationChange = async (statusExpiration: string | null) => {
    if (statusExpiration === 'CUSTOM') {
      return;
    }
    setUserStatusData((prev) => ({ ...prev, loading: true }));
    try {
      await setUserStatus({
        status: userStatusData?.status ?? null,
        statusExpiration,
        teamId: userStatusData?.teamId ?? DEFAULT_TEAM,
      });
      setUserStatusData((prev) => ({
        ...prev,
        data: {
          ...(prev.data as UserStatusData),
          status: userStatusData?.status ?? null,
          statusExpiration,
        },
      }));
      addNotification({
        message: 'Your status expiration has been updated',
        type: 'success',
      });
      postMessageToIframe(
        'profile',
        JSON.stringify({
          type: 'SET_USER_STATUS',
          data: { status: userStatusData?.status ?? null, statusExpiration },
        })
      );
    } catch (error) {
      addNotification({
        message:
          'Something went wrong. Your status expiration could not be updated',
        type: 'error',
      });
    } finally {
      setSelectStatusModalOpen(false);
      setSelectStatusExpirationModalOpen(false);
      setIsOnlineStatusConfirmModalOpen(false);
      setIsOfflineStatusConfirmModalOpen(false);
      setUserStatusData((prev) => ({ ...prev, loading: false }));
    }
  };

  const onStatusAndStatusExpirationChange = async (
    status: WabaIntegrationMemberStatus | null,
    statusExpiration: string | null
  ) => {
    setUserStatusData((prev) => ({ ...prev, loading: true }));
    try {
      const updatedStatus =
        typeof status === 'string' && status.length > 0 ? status : null;
      const updatedStatusExpiration =
        statusExpiration === 'CUSTOM' ||
        status === WabaIntegrationMemberStatus.ACTIVE
          ? null
          : statusExpiration;
      await setUserStatus({
        status: updatedStatus,
        statusExpiration: updatedStatusExpiration,
        teamId: userStatusData?.teamId ?? DEFAULT_TEAM,
      });
      setUserStatusData((prev) => ({
        ...prev,
        data: {
          ...(prev.data as UserStatusData),
          status: updatedStatus,
          statusExpiration: updatedStatusExpiration,
        },
      }));
      addNotification({
        message: 'Your status has been updated',
        type: 'success',
      });
      postMessageToIframe(
        'profile',
        JSON.stringify({
          type: 'SET_USER_STATUS',
          data: {
            status: updatedStatus,
            statusExpiration: updatedStatusExpiration,
          },
        })
      );
    } catch (error) {
      addNotification({
        message: 'Something went wrong. Your status could not be updated',
        type: 'error',
      });
    } finally {
      setSelectStatusModalOpen(false);
      setSelectStatusExpirationModalOpen(false);
      setIsOnlineStatusConfirmModalOpen(false);
      setSelectStatusExpirationModalOpen(false);
      setUserStatusData((prev) => ({ ...prev, loading: false }));
    }
  };

  const renderOnlineStatusConfirmationModal = () => {
    return (
      <ConfirmationModal
        open={isOnlineStatusConfirmModalOpen}
        onClose={(reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            setIsOnlineStatusConfirmModalOpen(false);
          } else {
            setSelectStatusModalOpen(false);
            setSelectStatusExpirationModalOpen(false);
            setIsOnlineStatusConfirmModalOpen(false);
            setIsOfflineStatusConfirmModalOpen(false);
            localStorage.setItem(
              USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME,
              new Date().toISOString()
            );
          }
        }}
        onClick={async () => {
          setSelectStatusModalOpen(true);
          setSelectStatusExpirationModalOpen(false);
          setIsOnlineStatusConfirmModalOpen(false);
          setIsOfflineStatusConfirmModalOpen(false);
        }}
        icon={
          <WelcomeBackIcon
            height={24}
            width={24}
            css={css`
              height: 24px;
              min-height: 24px;
              max-height: 24px;
              width: 24px;
              min-width: 24px;
              max-width: 24px;
            `}
          />
        }
        hideBackDrop={true}
        headerTitle="Welcome back! Please update your status"
        message="Select your status to continue."
        okButtonText="SELECT STATUS"
        cancelButtonText="STAY OFFLINE"
        shouldHandleLoading={false}
        modalWrapperStyles={css`
          position: unset;
        `}
        modalContainerStyles={css`
          top: ${HEADER_HEIGHT + 16}px;
          left: unset;
          right: 16px;
          transform: translate(0px, 0px);
          max-width: 450px;
          box-shadow: 0px 9px 46px 8px #0000001f;
        `}
        modalHeaderStyles={css`
          font-size: 18px;
          font-weight: 600;
        `}
        modalMessageStyles={css`
          color: #7a7a7a;
          font-size: 16px;
          font-weight: 500;
          padding-left: calc(24px + 12px);
        `}
      />
    );
  };

  const renderOfflineStatusConfirmationModal = () => {
    return (
      <ConfirmationModal
        open={isOfflineStatusConfirmModalOpen}
        onClose={(reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            setIsOfflineStatusConfirmModalOpen(false);
          } else {
            setSelectStatusModalOpen(false);
            setSelectStatusExpirationModalOpen(false);
            setIsOnlineStatusConfirmModalOpen(false);
            setIsOfflineStatusConfirmModalOpen(false);
            localStorage.setItem(
              USER_STATUS_STORAGE_KEYS.LAST_OFFLINE_SYNCED_TIME,
              new Date().toISOString()
            );
          }
        }}
        onClick={async () => {
          setSelectStatusModalOpen(true);
          setSelectStatusExpirationModalOpen(false);
          setIsOnlineStatusConfirmModalOpen(false);
          setIsOfflineStatusConfirmModalOpen(false);
        }}
        icon={
          <UpdateStatusIcon
            height={24}
            width={24}
            css={css`
              height: 24px;
              min-height: 24px;
              max-height: 24px;
              width: 24px;
              min-width: 24px;
              max-width: 24px;
            `}
          />
        }
        hideBackDrop={true}
        headerTitle="Update your status"
        message="Hey you have been inactive for 10 minutes. Please change your status to continue."
        okButtonText="UPDATE STATUS"
        cancelButtonText="STAY OFFLINE"
        shouldHandleLoading={false}
        modalWrapperStyles={css`
          position: unset;
        `}
        modalContainerStyles={css`
          top: ${HEADER_HEIGHT + 16}px;
          left: unset;
          right: 16px;
          transform: translate(0px, 0px);
          max-width: 450px;
          box-shadow: 0px 9px 46px 8px #0000001f;
        `}
        modalHeaderStyles={css`
          font-size: 18px;
          font-weight: 600;
        `}
        modalMessageStyles={css`
          color: #7a7a7a;
          font-size: 16px;
          font-weight: 500;
          padding-left: calc(24px + 12px);
        `}
      />
    );
  };

  const renderLogoutModal = () => {
    return (
      <QSModal open={open} onClose={() => setOpen(false)}>
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50%;
            padding: 32px;
            left: 50%;
            transform: translate(-50%, -50%);
            background: white;
            border-radius: 5px;
            &:focus-visible {
              outline: none;
              border: none;
            }
          `}
        >
          <QSTypography
            css={css`
              font-size: 18px;
              margin-bottom: 24px;
            `}
          >
            Are you sure you want to logout?
          </QSTypography>
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
            `}
          >
            <QSButton
              css={css`
                margin-right: 16px;
                padding: 4px 12px;
                font-size: 16px;
                background: none;
                :hover {
                  background: #efefef90;
                }
              `}
              onClick={() => {
                setOpen(false);
              }}
              variant="text"
            >
              CANCEL
            </QSButton>
            <QSButton
              css={css`
                padding: 4px 16px;
                font-size: 16px;
                color: white;

                :hover {
                  background: rgba(63, 144, 109, 0.9);
                }
              `}
              onClick={async () => {
                try {
                  await deleteMessagingToken();
                } catch (error) {
                  // Do nothing
                }
                await firebaseAuth.signOut();
                trackLogout();
                setLoginStep(LoginStep.PHONE);
                navigate(
                  { pathname: ROUTES.LOGIN.replace('/*', '') },
                  { replace: true }
                );
                setUserProfile(undefined);
              }}
            >
              LOGOUT
            </QSButton>
          </Box>
        </Box>
      </QSModal>
    );
  };

  const renderTeamMemberStatusIcon = (
    status: WabaIntegrationMemberStatus | null,
    showAvailabilityIcon?: boolean,
    iconSize?: number
  ) => {
    const Icon = showAvailabilityIcon
      ? getTeamMemberAvailabilityIcon(status)
      : getTeamMemberStatusIcon(status);
    return (
      <Icon
        css={css`
          display: block;
          height: ${iconSize ?? 12}px;
          min-height: ${iconSize ?? 12}px;
          max-height: ${iconSize ?? 12}px;
          width: ${iconSize ?? 12}px;
          min-width: ${iconSize ?? 12}px;
          max-width: ${iconSize ?? 12}px;
        `}
        height={iconSize ?? 12}
        width={iconSize ?? 12}
      />
    );
  };

  const renderTeamMemberStatusMenuItem = (
    status: WabaIntegrationMemberStatus | null,
    showSelectedCheck?: boolean,
    iconSize?: number
  ) => {
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 12px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: 12px;
          `}
        >
          {renderTeamMemberStatusIcon(status, false, iconSize)}
          {typeof status === 'string' && status.length > 0 && (
            <QSTypography
              css={css`
                font-size: 14px;
                font-weight: 400;
                color: inherit;
                word-wrap: break-word;
                word-break: break-all;
              `}
            >
              {getTeamMemberStatusDisplayLabel(status)}
            </QSTypography>
          )}
        </div>
        {showSelectedCheck &&
          typeof status === 'string' &&
          status.length > 0 &&
          status === teamMemberStatus?.status &&
          (userStatusLoading ? (
            <Loader size={24} />
          ) : (
            <CheckIcon
              css={css`
                color: #3f906c;
              `}
            />
          ))}
      </div>
    );
  };

  const renderTeamMemberStatusMenu = () => {
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 8px;
        `}
      >
        <QSTypography
          css={css`
            font-size: 14px;
            font-weight: 400;
            flex: 0 1 auto;
            word-wrap: break-word;
            word-break: break-all;
          `}
        >
          Update your status
        </QSTypography>
        <DropdownComponent<WabaIntegrationMemberStatus>
          {...deepmerge<
            Omit<
              Partial<DropdownProps<string | number | undefined>>,
              'value' | 'onChange'
            >
          >(chatSettingsDropdownProps, {
            searchable: false,
            sx: {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
              '.MuiInputBase-root': {
                fontSize: '14px',
                fontWeight: '400',
                color: '#2D2D2D',
              },
            },
            menuSx: {
              '.MuiButtonBase-root': {
                fontSize: '14px',
                fontWeight: '400',
                color: '#2D2D2D',
              },
            },
          })}
          disabled={trainingLinkLoading || userStatusLoading}
          children={userStatusLoading ? <Loader size={24} /> : null}
          value={teamMemberStatus.status ?? ('' as WabaIntegrationMemberStatus)}
          onChange={(status) =>
            setTeamMemberStatus((prev) => ({ ...prev, status }))
          }
          options={Object.values(WabaIntegrationMemberStatus).map((status) => ({
            name: getTeamMemberStatusDisplayLabel(status),
            value: capitalizeFirstLetter(status) as WabaIntegrationMemberStatus,
          }))}
          renderOption={({ value }) =>
            renderTeamMemberStatusMenuItem(value, false, 12)
          }
          renderValue={(status) => {
            if (typeof status === 'string' && status.length > 0) {
              return renderTeamMemberStatusMenuItem(status, false, 12);
            }
            return 'Update your status';
          }}
        />
      </div>
    );
  };

  const renderTeamMemberStatusExpirationMenu = () => {
    if (
      [
        WabaIntegrationMemberStatus.ACTIVE,
        WabaIntegrationMemberStatus.OFFLINE,
      ].indexOf(teamMemberStatus.status as WabaIntegrationMemberStatus) > -1
    ) {
      return null;
    }
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          row-gap: 8px;
        `}
      >
        <QSTypography
          css={css`
            font-size: 14px;
            font-weight: 400;
            flex: 0 1 auto;
            word-wrap: break-word;
            word-break: break-all;
            &:first-letter {
              text-transform: uppercase;
            }
          `}
        >
          Remove status after
        </QSTypography>
        <DropdownComponent<string>
          {...deepmerge<
            Omit<
              Partial<DropdownProps<string | number | undefined>>,
              'value' | 'onChange'
            >
          >(chatSettingsDropdownProps, {
            searchable: false,
            sx: {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
              '.MuiInputBase-root': {
                fontSize: '14px',
                fontWeight: '400',
                color: '#2D2D2D',
              },
            },
            menuSx: {
              '.MuiButtonBase-root': {
                fontSize: '14px',
                fontWeight: '400',
                color: '#2D2D2D',
              },
            },
          })}
          disabled={trainingLinkLoading || userStatusLoading}
          children={userStatusLoading ? <Loader size={24} /> : null}
          value={teamMemberStatus.statusExpiration ?? ''}
          onChange={(statusExpiration) => {
            let updatedStatusExpiration: string | null = null;
            if (statusExpiration === 'DONT_CLEAR') {
              updatedStatusExpiration = null;
            } else if (statusExpiration === '30_MINS') {
              updatedStatusExpiration = dayjs(new Date())
                .add(30, 'minutes')
                .toISOString();
            } else if (statusExpiration === '1_HOUR') {
              updatedStatusExpiration = dayjs(new Date())
                .add(1, 'hour')
                .toISOString();
            } else if (statusExpiration === '4_HOURS') {
              updatedStatusExpiration = dayjs(new Date())
                .add(4, 'hours')
                .toISOString();
            } else if (statusExpiration === '1_DAY') {
              updatedStatusExpiration = dayjs(new Date())
                .add(1, 'day')
                .toISOString();
            } else if (statusExpiration === 'CUSTOM') {
              updatedStatusExpiration = 'CUSTOM';
            }
            if (statusExpiration !== 'CUSTOM') {
              setTeamMemberStatus((prev) => ({
                ...prev,
                statusExpiration: updatedStatusExpiration,
              }));
            }
          }}
          onMenuItemClick={(value) => {
            if (value === 'CUSTOM') {
              openDateTimePicker();
            }
          }}
          options={[
            { name: `Don't clear`, value: 'DONT_CLEAR' },
            { name: '30 minutes', value: '30_MINS' },
            { name: '1 hour', value: '1_HOUR' },
            { name: '4 hours', value: '4_HOURS' },
            { name: '1 day', value: '1_DAY' },
            { name: 'Custom', value: 'CUSTOM' },
          ]}
          renderValue={(status) => {
            if (typeof status === 'string' && status.length > 0) {
              switch (status) {
                case '30_MINS': {
                  return '30 minutes';
                }
                case '1_HOUR': {
                  return '1 hour';
                }
                case '4_HOURS': {
                  return '4 hours';
                }
                case '1_DAY': {
                  return '1 day';
                }
                default: {
                  return dayjs(status).isValid()
                    ? dayjs(status).format('h:mm A, Do MMM YYYY')
                    : `Don't clear`;
                }
              }
            }
            return `Don't clear`;
          }}
        />
      </div>
    );
  };

  const renderSelectStatusModal = () => {
    return (
      <ConfirmationModal
        open={selectStatusModalOpen}
        onClose={() => setSelectStatusModalOpen(false)}
        onClick={() =>
          onStatusAndStatusExpirationChange(
            teamMemberStatus.status,
            teamMemberStatus.statusExpiration
          )
        }
        showActions={false}
        children={
          <Box
            css={css`
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
            `}
          >
            {Object.values(WabaIntegrationMemberStatus).map((status, index) => {
              return (
                <QSButtonBase
                  key={index}
                  css={css`
                    height: 36px;
                    min-height: 36px;
                    max-height: 36px;
                    width: 100%;
                    padding: 8px 12px;
                    box-shadow: none;
                    cursor: pointer;

                    &:hover {
                      text-decoration: none;
                      background: rgba(0, 0, 0, 0.04);
                    }
                  `}
                  disableRipple={trainingLinkLoading || userStatusLoading}
                  disabled={trainingLinkLoading || userStatusLoading}
                  onClick={() => {
                    setTeamMemberStatus((prev) => ({
                      ...prev,
                      status,
                      statusExpiration:
                        userStatusData?.statusExpiration ?? null,
                    }));
                    if (
                      [
                        WabaIntegrationMemberStatus.ACTIVE,
                        WabaIntegrationMemberStatus.OFFLINE,
                      ].indexOf(status as WabaIntegrationMemberStatus) > -1
                    ) {
                      onStatusChange(status);
                    } else {
                      setSelectStatusModalOpen(false);
                      setSelectStatusExpirationModalOpen(true);
                    }
                  }}
                >
                  {renderTeamMemberStatusMenuItem(status, true, 16)}
                </QSButtonBase>
              );
            })}
          </Box>
        }
        shouldHandleLoading={false}
        modalContainerStyles={css`
          top: ${HEADER_HEIGHT + 16}px;
          left: unset;
          right: 16px;
          transform: translate(0px, 0px);
          min-width: 250px;
          max-width: 450px;
          max-height: 450px;
          padding: 0px;
          overflow: auto;
        `}
      />
    );
  };

  const renderSelectStatusExpirationModal = () => {
    return (
      <ConfirmationModal
        open={selectStatusExpirationModalOpen}
        onClose={() => {
          setTeamMemberStatus((prev) => ({
            ...prev,
            status: userStatusData?.status ?? null,
            statusExpiration: userStatusData?.statusExpiration ?? null,
          }));
          setSelectStatusExpirationModalOpen(false);
        }}
        onClick={() =>
          onStatusAndStatusExpirationChange(
            teamMemberStatus.status,
            teamMemberStatus.statusExpiration
          )
        }
        children={
          <Box
            css={css`
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              row-gap: 16px;
              padding: 16px;
              background: #ffffff;
              border-bottom: 1px solid ${BORDER_COLOR};
              border-radius: 5px;
              padding-bottom: 20px;
              &:focus-visible {
                outline: none;
                border: none;
              }
            `}
          >
            {renderTeamMemberStatusMenu()}
            {renderTeamMemberStatusExpirationMenu()}
          </Box>
        }
        headerTitle="Update your status"
        okButtonText="SELECT"
        cancelButtonText="CANCEL"
        shouldHandleLoading={false}
        okButtonProps={{
          disabled:
            typeof teamMemberStatus.status !== 'string' ||
            teamMemberStatus.status.length === 0,
        }}
        modalContainerStyles={css`
          min-width: 350px;
          max-width: 350px;
          max-height: 450px;
          padding: 0px;
          overflow: auto;
        `}
        modalHeaderContainerStyles={css`
          width: 100%;
          padding: 16px;
          border-bottom: 1px solid ${BORDER_COLOR};
        `}
        modalHeaderStyles={css`
          font-size: 16px;
          padding: 0px;
        `}
        modalActionsContainerStyles={css`
          padding: 16px;
        `}
      />
    );
  };

  const renderDateTimePicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker<Dayjs>
          ampm={true}
          ampmInClock={true}
          closeOnSelect={false}
          disabled={trainingLinkLoading || userStatusLoading}
          value={
            typeof teamMemberStatus.statusExpiration === 'string' &&
            teamMemberStatus.statusExpiration.length > 0 &&
            dayjs(teamMemberStatus.statusExpiration).isValid()
              ? dayjs(teamMemberStatus.statusExpiration)
              : null
          }
          onAccept={(statusExpiration) => {
            setTeamMemberStatus((prev) => ({
              ...prev,
              statusExpiration: statusExpiration
                ? statusExpiration.toISOString()
                : null,
            }));
          }}
          minDateTime={dayjs(new Date()).startOf('day')}
          css={css`
            display: none;
          `}
          slotProps={{
            dialog: {
              sx: { zIndex: '1600' },
            },
          }}
          open={dateTimePickerOpen}
          onOpen={openDateTimePicker}
          onClose={closeDateTimePicker}
        />
      </LocalizationProvider>
    );
  };

  const handleOnGetHelpClick = () => {
    let encodedMessage = encodeURIComponent(
      'I want to learn more about DoubleTick'
    );
    if (hideDTDetails) {
      if (channelData?.name) {
        encodedMessage = encodeURIComponent(
          `I want to learn more about ${channelData?.name}`
        );
      } else {
        encodedMessage = encodeURIComponent('I want to learn more');
      }
      openPopup(
        `https://wa.me/${channelData.supportNumber}?text=${encodedMessage}`
      );
    } else {
      openPopup(`https://wa.me/918356849474?text=${encodedMessage}`);
    }
  };

  return (
    <Fragment>
      <Box
        css={css`
          border-bottom: 1px solid rgba(224, 224, 234, 1);
          height: ${HEADER_HEIGHT}px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 16px;
          background: linear-gradient(
            68deg,
            rgb(255, 255, 255) 0%,
            rgb(242, 242, 242) 100%
          );
          padding: 0px 16px 0px 0px;
        `}
      >
        <Box
          css={css`
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          `}
        >
          <Box
            css={css`
              margin: 4px 12px 0 18px;
            `}
          >
            <Logo height={35} width={35} />
          </Box>
          <QSTypography
            sx={{
              fontSize: '20px',
              fontWeight: '650',
            }}
          >
            {channelData?.name ? channelData?.name : 'DoubleTick'}
          </QSTypography>
        </Box>

        <Box
          css={css`
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 8px;
            position: relative;
          `}
        >
          {showLowBalanceBanner ? (
            <Box
              css={css`
                height: 32px;
                padding: 6px 12px;
                background: #f8eedf;
                box-shadow: 0px 1px 2px 0px #61616133;
                border-radius: 4px;
                display: flex;
                gap: 12px;
              `}
            >
              <ErrorIcon
                width={18}
                height={18}
                css={css`
                  color: #e7951c;
                `}
                fill="#E7951C"
              />
              <QSTypography
                css={css`
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 17px;
                  letter-spacing: 0px;
                  text-align: left;
                  color: #2d2d2d;
                `}
              >
                Your wallet balance is low
              </QSTypography>
              <QSTypography
                css={css`
                  font-family: Inter;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 17px;
                  letter-spacing: 0px;
                  text-align: left;
                  color: #585858;
                `}
              >
                Recharge now to send more messages
              </QSTypography>
              {!hideDTDetails ? (
                <QSButton
                  variant="text"
                  css={css`
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #3f906d;
                    text-decoration: underline;
                    &:hover {
                      text-decoration: underline;
                    }
                  `}
                  onClick={handleOnClickRecharge}
                >
                  Recharge now
                </QSButton>
              ) : null}
            </Box>
          ) : null}
          {!hideDTDetails ? (
            <QSButton
              onClick={() => {
                openPopup(DT_ROADMAP_LINK, '_blank');
              }}
              variant="text"
              css={css`
                box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
                background: rgba(251, 251, 252, 1);
                color: #2d2d2d;
                border-radius: 4px;
                padding: 6px 12px 6px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 8px;
              `}
            >
              <RequestFeatureIcon fill="#3f906d" width={18} height={18} />
              <QSTypography
                css={css`
                  font-size: 14px;
                  font-weight: 600;
                `}
              >
                Request a feature
              </QSTypography>
            </QSButton>
          ) : null}
          {!hideDTDetails && trainingLinkData && (
            <QSButton
              onClick={() => {
                window.open(trainingLinkData.link, '_blank');
              }}
              variant="text"
              css={css`
                box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
                background: rgba(251, 251, 252, 1);
                color: #2d2d2d;
                border-radius: 4px;
                padding: 6px 12px 6px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 8px;
              `}
            >
              <SchoolIcon size={18} />
              <QSTypography
                css={css`
                  font-size: 14px;
                  font-weight: 600;
                `}
              >
                DoubleTick Training
              </QSTypography>
            </QSButton>
          )}
          <QSButton
            onClick={handleOnGetHelpClick}
            variant="text"
            css={css`
              box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
              background: rgba(251, 251, 252, 1);
              color: #2d2d2d;
              border-radius: 4px;
              padding: 6px 12px 6px 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 8px;
            `}
          >
            <HelpOutlineIcon
              css={css`
                color: #3f906d;
                font-size: 18px;
              `}
            />
            <QSTypography
              css={css`
                font-size: 14px;
                font-weight: 600;
              `}
            >
              Get help
            </QSTypography>
          </QSButton>

          {/* <QSButtonBase
            disabled={trainingLinkLoading || userStatusLoading}
            onClick={() => setSelectStatusModalOpen(true)}
            css={css`
              height: 36px;
              min-height: 36px;
              max-height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              column-gap: 12px;
              box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);
              background: rgba(251, 251, 252, 1);
              color: #2d2d2d;
              border-radius: 40px;
              padding: 4px ${(typeof userStatusData?.status === 'string' && userStatusData?.status.length > 0) ? 12 : 6}px 4px 6px;
            `}
          >
            <SchoolIcon
              css={css`
                height: 20px;
                min-height: 20px;
                max-height: 20px;
                width: 20px;
                min-width: 20px;
                max-width: 20px;
              `}
              size={20}
            />
            <QSTypography
              css={css`
                margin: 0px 12px 0px 6px;
                font-size: 14px;
                font-weight: 500;
              `}
            >
              DoubleTick Training
            </QSTypography>
            <ArrowRightFilled
              css={css`
                height: 20px;
                min-height: 20px;
                max-height: 20px;
                width: 20px;
                min-width: 20px;
                max-width: 20px;
              `}
              size={20}
            />
          </QSButtonBase> */}
        </Box>
      </Box>

      {renderDateTimePicker()}
      {renderLogoutModal()}
      {renderOnlineStatusConfirmationModal()}
      {renderOfflineStatusConfirmationModal()}
      {renderSelectStatusModal()}
      {renderSelectStatusExpirationModal()}
    </Fragment>
  );
};

export default Header;

// {/* TODO add in next release */}
// {/*<QSButtonBase*/}
// {/*  disabled={trainingLinkLoading || userStatusLoading}*/}
// {/*  onClick={() => setSelectStatusModalOpen(true)}*/}
// {/*  css={css`*/}
// {/*    height: 36px;*/}
// {/*    min-height: 36px;*/}
// {/*    max-height: 36px;*/}
// {/*    display: flex;*/}
// {/*    justify-content: center;*/}
// {/*    align-items: center;*/}
// {/*    column-gap: 12px;*/}
// {/*    box-shadow: 0px 1px 2px 0px rgba(97, 97, 97, 0.2);*/}
// {/*    background: rgba(251, 251, 252, 1);*/}
// {/*    color: #2d2d2d;*/}
// {/*    border-radius: 40px;*/}
// {/*    padding: 4px ${(typeof userStatusData?.status === 'string' && userStatusData?.status.length > 0) ? 12 : 6}px 4px 6px;*/}
// {/*  `}*/}
// {/*>*/}
// {/*  {userStatusLoading ? (*/}
// {/*    <Loader size={24} />*/}
// {/*  ) : (*/}
// {/*    <Avatar*/}
// {/*      css={css`*/}
// {/*        height: 24px;*/}
// {/*        min-height: 24px;*/}
// {/*        width: 24px;*/}
// {/*        min-width: 24px;*/}
// {/*        font-size: 14px;*/}
// {/*        font-weight: 600;*/}
// {/*        color: #2D2D2D;*/}
// {/*        background-color: ${getColorFromPhone({ phone: userDetails?.phone ?? '', name: userDetails?.name ?? '' })};*/}
// {/*        display: flex;*/}
// {/*        justify-content: center;*/}
// {/*        align-items: center;*/}
// {/*        flex: 0 0 auto;*/}
// {/*        text-transform: uppercase;*/}
// {/*      `}*/}
// {/*    >*/}
// {/*      {(userDetails?.name ?? '').replace(new RegExp(UNICODE_ZERO_WIDTH_REGEX), '').trim()[0]}*/}
// {/*    </Avatar>*/}
// {/*  )}*/}
// {/*  {typeof userStatusData?.status === 'string' && userStatusData?.status.length > 0 && (*/}
// {/*    <Fragment>*/}
// {/*      {!userStatusLoading && (*/}
// {/*        <div*/}
// {/*          css={css`*/}
// {/*            position: absolute;*/}
// {/*            top: 18px;*/}
// {/*            left: 18px;*/}
// {/*            color: inherit;*/}
// {/*            background-color: #FFFFFF;*/}
// {/*            border-radius: 50%;*/}
// {/*            height: 14px;*/}
// {/*            width: 14px;*/}
// {/*            padding: 3px;*/}
// {/*            display: flex;*/}
// {/*            justify-content: center;*/}
// {/*            align-items: center;*/}
// {/*            z-index: 1;*/}
// {/*          `}*/}
// {/*        >*/}
// {/*          {renderTeamMemberStatusIcon(userStatusData?.status, true, 12)}*/}
// {/*        </div>*/}
// {/*      )}*/}
// {/*      <div*/}
// {/*        css={css`*/}
// {/*          color: inherit;*/}
// {/*          background-color: transparent;*/}
// {/*          border-radius: 50%;*/}
// {/*          height: 16px;*/}
// {/*          width: 16px;*/}
// {/*          display: flex;*/}
// {/*          justify-content: center;*/}
// {/*          align-items: center;*/}
// {/*        `}*/}
// {/*      >*/}
// {/*        {renderTeamMemberStatusIcon(userStatusData?.status, false, 16)}*/}
// {/*      </div>*/}
// {/*    </Fragment>*/}
// {/*  )}*/}
// {/*</QSButtonBase>*/}
