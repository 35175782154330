export const deserializeTagsFromQueryParams = (
  queryParams: URLSearchParams
) => {
  let deserializedTags = [];
  try {
    deserializedTags =
      queryParams.has('tags') &&
      (queryParams.get('tags') as string).trim() !== ''
        ? JSON.parse(queryParams.get('tags') as string)
        : [];
  } catch (e) {
    deserializedTags = [];
  }

  return Array.isArray(deserializedTags)
    ? (deserializedTags as string[]).filter(Boolean)
    : [];
};
