import packageDetails from '../../../package.json';

type AppConfig = {
  appVersion: string;
  iframeHost: string;
  botBuilderHost: string;
  waPartnerId: string;
  mixPanelToken: string;
  miniPaymentsHost: string;
  sentryDsn: string;
  firebaseVapidKey: string;
  recaptchaSiteKey: string;
};

const appVersion = packageDetails.version;

let appConfig: AppConfig;

if (process.env.REACT_APP_ENV === 'production') {
  appConfig = {
    appVersion,
    iframeHost: 'https://mini.doubletick.io',
    botBuilderHost: 'https://flow.doubletick.io',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    sentryDsn:
      'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'production-2') {
  appConfig = {
    appVersion,
    iframeHost: 'https://mini.doubletick.io',
    botBuilderHost: 'https://flow.doubletick.io',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    sentryDsn:
      'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging2') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'apiStaging3') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'stagingWithProd') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    sentryDsn:
      'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else if (process.env.REACT_APP_ENV === 'staging2WithProd') {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini-2.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://mini.payments.quicksell.co',
    waPartnerId: 'ickmk2PA',
    mixPanelToken: '2fba977e7c1b9ee5c7262a7dd79f48ad',
    firebaseVapidKey:
      'BE5yadnH2LHES3VWKkyJwYjPFX0IoUXQSx9iPdV7rm7AjaQ4q9hAFYnpbz-JpPCivRSKdsL9UUyfJXPAJfSM3Ow',
    sentryDsn:
      'https://2fa7420588e446179bba83e10a3ec631@o110511.ingest.sentry.io/4504167964540928',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
} else {
  appConfig = {
    appVersion,
    iframeHost: 'https://crm-mini.dev.quicksell.co',
    botBuilderHost: 'https://quickflow.dev.quicksell.co',
    miniPaymentsHost: 'https://onboarding.crm.dev.quicksell.co',
    waPartnerId: 'ickmk2PA',
    firebaseVapidKey:
      'BD4WgTgSuZl2T7kWAoNHd-cKWFJNpuVX2BKDcuMJB0NKLp79a3pDygx7PM8ueIn-LKrDzzvRmfHY9I3DoZvlo2g',
    mixPanelToken: '65c4a7fd57f808f09e2e1694068f2cf0',
    sentryDsn: '',
    recaptchaSiteKey: '6LfRvlQoAAAAAPRwKLB17C6Q1hS73KNNm4BBUdIl',
  };
}

export default appConfig;
