import { io, Socket } from 'socket.io-client';
import requestConfig from '../../shared/config/request';
import { socketOptions } from './index';

let organizationsSocket: Socket;

export const getOrganizationsSocket = (): Socket => {
  if (organizationsSocket) {
    return organizationsSocket;
  }
  organizationsSocket = io(
    `${requestConfig.socketUrl}/organization`,
    socketOptions
  );

  organizationsSocket.on('connect', () => {
    document.dispatchEvent(
      new CustomEvent('organizations-socket-connect')
    );
  });
  organizationsSocket.on('disconnect', () => {});
  return organizationsSocket;
};
