/** @jsxImportSource @emotion/react */
import { Suspense, lazy, Fragment, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Loader from '../../../../shared/components/molecules/loader';
import getRoutesV1 from './v1';
import { LOADING } from '../../../../shared/constants';
import { IMPORT_EXCEL_STORAGE_KEYS } from '../../../../shared/constants/storage';
import { Header } from '../../../../shared/components/organisms/';
import { css } from '@emotion/react';
import { ROUTES } from '../../../../shared/constants/routes';
import { HEADER_HEIGHT, SIDEBAR_WIDTH } from '../../../../shared/typings';
import { useAtomValue, useSetAtom } from 'jotai';
import useMiniListeners from '../../hooks/useMiniListeners';
import { allUserPermissions, rootScope } from '../../../../shared/states/user';
import Sidebar from '../../components/sidebar';
import { CreateOwnAccountModalRenderer } from '../../../../shared/modules/demo-account/v1/components';
import Dialog360Callback from '../../components/dialog360';
import { ProtectedRoute } from './v1/protected-route';
import RechargeModal from '../../../../shared/components/molecules/sidebar-wallet/recharge-modal';
import BotBuilderModal from '../../../crm-mini/bots/v1/bot';
import { importExcelFilesStatusAtom } from '../../../customers/state';
import { getExcelSheetsStatus } from '../../../customers/requests';
import { OperationStatus } from '../../../home/v1/typings';
import useImportExcelSocket from '../../../home/v1/hooks/use-import-excel-socket';
import useNotifications from '../../../../shared/hooks/use-notifications';
import useAgentStatusSocket from '../../../home/v1/hooks/use-agent-status-socket';
import UpgradePlanModal from '../../../../shared/components/molecules/upgrade-plan-modal';
import NegativeWalletBalanceModal from '../../../../shared/components/molecules/sidebar-wallet/negative-balance-blocker';
import { walletDetailsAtom } from '../../../../shared/states/wallet';

const SignupLazyComponent = lazy(
  () => import('../../../login/v1/components/signup')
);
const ShopifySignupLazyComponent = lazy(
  () => import('../../../shopify/v1/components/shopify-signup')
);
const SignInLazyComponent = lazy(
  () => import('../../../login/v1/components/signin')
);
const LoginLazyComponent = lazy(
  () => import('../../../login/v1/components/login')
);
const BillingDetailsLazyComponent = lazy(
  () => import('../../../login/v1/components/billing-details-page')
);
const CompanyDetailsLazyComponent = lazy(
  () => import('../../../login/v1/components/company-details-page')
);
const EmailVerificationLazyComponent = lazy(
  () => import('../../../login/v1/components/email-verification-page')
);
const NextStepsLazyComponent = lazy(
  () => import('../../../login/v1/components/next-step')
);
const AutoLoginLazyComponent = lazy(
  () => import('../../../login/v1/components/auto-login')
);

const RenewPlanLazyComponent = lazy(
  () => import('../../../renew-plan/v1/components')
);

const PaymentScreenMiniLazyComponent = lazy(
  () => import('../../../renew-plan/v1/components/payment-screen-mini')
);

export function AppProtectedRoutes() {
  const userPermissions = useAtomValue(allUserPermissions, rootScope);
  const setImportExcelFilesStatus = useSetAtom(importExcelFilesStatusAtom);
  const { data: walletDetails, loading: walletDetailsLoading } =
    useAtomValue(walletDetailsAtom);
  const { addNotification } = useNotifications();
  const isWalletBalanceNegative = useMemo(() => {
    if (!walletDetails || walletDetailsLoading) return false;
    switch (walletDetails.currencyCode) {
      case 'INR':
        return walletDetails.balance < -1000;
      case 'USD':
        return walletDetails.balance < -10;
      case 'AED':
        return walletDetails.balance < -25;
      default:
        return walletDetails.balance < -10;
    }
  }, [walletDetails, walletDetailsLoading]);

  useMiniListeners();
  useImportExcelSocket();
  useAgentStatusSocket();

  const syncCompletedExcelFilesStatus = async () => {
    try {
      const { operations } = await getExcelSheetsStatus();
      if (operations.length === 0) return;
      setImportExcelFilesStatus(
        operations.filter(({ status }) => status === OperationStatus.COMPLETE)
      );
      localStorage.removeItem(
        IMPORT_EXCEL_STORAGE_KEYS.IMPORTED_EXCEL_SHEETS_STATUS
      );
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Error while fetching excel sheets status.',
      });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem(
        IMPORT_EXCEL_STORAGE_KEYS.IMPORTED_EXCEL_SHEETS_STATUS
      )
    ) {
      setImportExcelFilesStatus(null);
    } else {
      syncCompletedExcelFilesStatus();
    }
  }, []);

  return (
    <Fragment>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={ROUTES.CONVERSATIONS} replace />}
        />
        <Route
          path={ROUTES.SIGN_IN_WITH_CUSTOM_TOKEN}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <SignInLazyComponent />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.DIALOG_CALLBACK}
          key={ROUTES.DIALOG_CALLBACK}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <Dialog360Callback />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.BILLING_DETAILS}
          path={ROUTES.BILLING_DETAILS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <BillingDetailsLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.COMPANY_DETAILS}
          path={ROUTES.COMPANY_DETAILS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <CompanyDetailsLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.EMAIL_VERIFICATION}
          path={ROUTES.EMAIL_VERIFICATION}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <EmailVerificationLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.AUTO_LOGIN}
          path={ROUTES.AUTO_LOGIN}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <AutoLoginLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.NEXT_STEPS}
          path={ROUTES.NEXT_STEPS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <NextStepsLazyComponent />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.RENEW_PLAN}
          element={
            <ProtectedRoute hasAccess component={RenewPlanLazyComponent} />
          }
        />
        <Route
          path={ROUTES.RENEW_ACCOUNT}
          element={
            <ProtectedRoute
              component={PaymentScreenMiniLazyComponent}
              hasAccess
            />
          }
        />
        <Route
          key={'/*'}
          path={'/*'}
          element={
            <>
              <Header />
              <div
                css={css`
                  height: calc(100% - ${HEADER_HEIGHT}px);
                `}
              >
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex: 1;
                    position: relative;
                  `}
                >
                  <Sidebar permissions={userPermissions} />
                  <RechargeModal />
                  <UpgradePlanModal />
                  <BotBuilderModal />
                  <NegativeWalletBalanceModal
                    showNegativeWalletBalanceModal={isWalletBalanceNegative}
                  />
                  <div
                    css={css`
                      height: 100%;
                      width: 100%;
                      min-width: 100%;
                      max-width: 100%;
                      flex: 1;
                      padding-left: ${SIDEBAR_WIDTH}px;
                      overflow: hidden;
                    `}
                  >
                    <Routes>{getRoutesV1(userPermissions)}</Routes>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </Routes>
      <CreateOwnAccountModalRenderer />
    </Fragment>
  );
}

export function AppUnprotectedRoutes() {
  return (
    <Routes>
      <Route
        key={ROUTES.SIGNUP}
        path={ROUTES.SIGNUP}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <SignupLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.SHOPIFY_SIGNUP}
        path={ROUTES.SHOPIFY_SIGNUP}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <ShopifySignupLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.SIGN_IN}
        path={ROUTES.SIGN_IN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <SignInLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.LOGIN}
        path={ROUTES.LOGIN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <LoginLazyComponent />
          </Suspense>
        }
      />
      <Route
        path={ROUTES.SIGN_IN_WITH_CUSTOM_TOKEN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <SignInLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.NEXT_STEPS}
        path={ROUTES.NEXT_STEPS}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <NextStepsLazyComponent />
          </Suspense>
        }
      />
      <Route
        path={ROUTES.DIALOG_CALLBACK}
        key={ROUTES.DIALOG_CALLBACK}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <Dialog360Callback />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.AUTO_LOGIN}
        path={ROUTES.AUTO_LOGIN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <AutoLoginLazyComponent />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={<Navigate to={ROUTES.SIGNUP.replace('/*', '')} />}
      />
    </Routes>
  );
}
