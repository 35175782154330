/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState, useEffect, CSSProperties, useCallback } from 'react';
import { getFirebaseToken } from '../../../../infra/auth/firebase-auth';
import appConfig from '../../../config/app';
import { postMessageToIframe } from '../../../utils/post-message';
import { createQueryParams } from '../../../utils/url-helpers';
import Loader from '../../molecules/loader';
import { MiniIframeEvent } from '../../../constants/mini-iframe-events';
import { getUserChannelData } from '../../../../modules/login/v1/utility';

interface SettingsIframeProps {
  id: string;
  name?: string;
  route: string;
  queryParams: Array<{ key: string; value: string | undefined }>;
  title: string;
  sandbox?: string;
  iframeStyle?: CSSProperties;
  iFrameHostUrl?: string;
  onMessageReceived?: (messageEvent: MessageEvent) => void;
  onLoadIframe?: () => void;
}

const SettingsIframe: FC<SettingsIframeProps> = ({
  id,
  name,
  route,
  queryParams = [],
  title,
  sandbox,
  // sandbox = [
  //   'allow-forms',
  //   'allow-modals',
  //   'allow-orientation-lock',
  //   'allow-pointer-lock',
  //   'allow-scripts',
  //   'allow-popups-to-escape-sandbox',
  //   'allow-same-origin',
  //   'allow-popups',
  //   'allow-presentation',
  //   'allow-top-navigation',
  //   'allow-downloads'
  // ].join(', '),
  iframeStyle,
  iFrameHostUrl,
  onMessageReceived,
  onLoadIframe,
}) => {
  const [iframeLoaded, toggleIframeLoaded] = useState(false);
  const channelData = getUserChannelData();

  const getToken = useCallback(async () => {
    try {
      const token = await getFirebaseToken();
      return token;
    } catch (error) {
      // Do nothing
    }
  }, []);

  const sendToken = useCallback(async () => {
    const token = await getFirebaseToken();
    try {
      postMessageToIframe(
        id,
        JSON.stringify({
          data: { token },
          type: 'LOGIN',
        })
      );
      if (channelData?.name) {
        postMessageToIframe(
          id,
          JSON.stringify({
            type: 'CHANGE_CHANNEL_PARTNER',
            data: channelData,
          })
        );
      }
    } catch (error) {
      // Do nothing
    }
  }, [id]);

  useEffect(() => {
    getToken();

    // refresh after 65 mins (5 mins buffer, as token gets refreshed every 60 mins)
    const interval = setInterval(() => {
      getToken();
    }, 65 * 60 * 1000);

    return () => clearInterval(interval);
  }, [getToken]);

  const tokenHandler = async (event: MessageEvent<{ type: string }>) => {
    if (!event || !event.data) {
      return;
    }
    switch (event.data.type) {
      case MiniIframeEvent.ASK_TOKEN: {
        sendToken();
        return;
      }
      default: {
        if (typeof onMessageReceived === 'function') {
          onMessageReceived(event);
        }
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', tokenHandler);
    const interval = setInterval(() => {
      sendToken();
    }, 65 * 60 * 1000);
    return () => {
      window.removeEventListener('message', tokenHandler);
      clearInterval(interval);
    };
  }, [sendToken, id, onMessageReceived]);

  const getQueryParams = () => {
    return createQueryParams([
      ...(queryParams || []),
      { key: 'source', value: 'desktop' },
    ]);
  };

  const getIframeAllowedFeatures = (): string[] => {
    const defaultIframeFeatures = [
      'clipboard-write',
      'clipboard-read',
      'microphone',
      'camera',
      'geolocation',
      'midi',
      'encrypted-media',
      'payment',
      'usb',
      'accelerometer',
      'gyroscope',
      'magnetometer',
      'autoplay',
      'display-capture',
      'fullscreen',
    ];
    try {
      const iframeFeatures = document.featurePolicy.allowedFeatures();
      return iframeFeatures.filter((feature) =>
        defaultIframeFeatures.includes(feature)
      );
    } catch (error) {
      return defaultIframeFeatures;
    }
  };

  const renderIframe = () => {
    return (
      <iframe
        id={id}
        css={css`
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          box-shadow: none;
        `}
        allow={getIframeAllowedFeatures().join('; ')}
        sandbox={sandbox}
        name={name ?? Date.now().toString()}
        src={`${
          iFrameHostUrl ?? appConfig.iframeHost
        }${route}${getQueryParams()}`}
        title={title}
        style={{ display: iframeLoaded ? 'flex' : 'none', ...iframeStyle }}
        onLoad={() => {
          toggleIframeLoaded(true);
          if (typeof onLoadIframe === 'function') {
            onLoadIframe();
          }
        }}
      />
    );
  };

  const renderLoader = () => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: white;
          width: 100%;
        `}
      >
        <Loader size="3rem" />
      </div>
    );
  };

  return (
    <div
      className={'settings-iframe-container'}
      css={css`
        display: flex;
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
      `}
    >
      {renderIframe()}
      {!iframeLoaded && renderLoader()}
    </div>
  );
};

export default SettingsIframe;
