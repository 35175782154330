import { lazy, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import Loader from '../../../../../shared/components/molecules/loader';
import { LOADING } from '../../../../../shared/constants';
import { ROUTES } from '../../../../../shared/constants/routes';
import { AllUserPermissions } from '../../../../../shared/typings/user';
import { ProtectedRoute } from './protected-route';

const HomePageLazyComponent = lazy(() => import('../../../../home/v1'));

const TeamManagementV1LazyComponent = lazy(
  () => import('../../../../crm-mini/team-management/v1')
);

const TeamManagementLazyComponent = lazy(
  () => import('../../../../crm-mini/team-management/v2')
);

const TeamManagementInvitesLazyComponent = lazy(
  () => import('../../../../crm-mini/team-management/v1/invites')
);

const BotsLazyComponent = lazy(() => import('../../../../crm-mini/bots/v1'));

const ChannelManagementLazyComponent = lazy(
  () => import('../../../../channels/components')
);

const ChannelMembersLazyComponent = lazy(
  () => import('../../../../crm-mini/channel-members/v1/components')
);

const CreateTemplateLazyComponent = lazy(
  () => import('../../../../crm-mini/create-template/v1')
);

const TemplatesLazyComponent = lazy(() => import('../../../../template-list'));

const CustomersLazyComponent = lazy(
  () => import('../../../../customers/components')
);

const AnalyticsLazyComponent = lazy(
  () => import('../../../../crm-mini/analytics/components')
);

const ReportsLazyComponent = lazy(
  () => import('../../../../crm-mini/reports/v1')
);

const SettingsLazyComponent = lazy(
  () => import('../../../../settings/v1/components')
);

const ScheduledBroadcastsLazyComponent = lazy(
  () => import('../../../../crm-mini/scheduled-broadcasts/v1')
);

const RequestsLazyComponent = lazy(
  () => import('../../../../crm-mini/pending-requests/v1')
);

const BotBuilderLazyComponent = lazy(
  () => import('../../../../bot-builder/components')
);

const SendTestBroadcastLazyComponent = lazy(
  () => import('../../../../login/v1/components/test-broadcast')
);

const ChatGptLazyComponent = lazy(
  () => import('../../../../crm-mini/chat-gpt/')
);

const EmbeddedSignupLazyComponent = lazy(
  () => import('../../../../embedded-signup/components')
);

const DashboardLazyComponent = lazy(
  () => import('../../../../crm-mini/dashboard/components')
);

export default function getRoutesV1(permissions: AllUserPermissions) {
  const routes = [
    <Route
      key={`${ROUTES.CONVERSATIONS}/*`}
      path={`${ROUTES.CONVERSATIONS}/*`}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <HomePageLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.BOTS}/*`}
      path={`${ROUTES.BOTS}/*`}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <BotsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.CHANNEL_MEMBER_MANAGEMENT}
      path={ROUTES.CHANNEL_MEMBER_MANAGEMENT}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <ChannelMembersLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.BOT_BUILDER}
      path={ROUTES.BOT_BUILDER}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <BotBuilderLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.CHANNEL_MANAGEMENT}
      path={ROUTES.CHANNEL_MANAGEMENT}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <ChannelManagementLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.CREATE_TEMPLATE}
      path={ROUTES.CREATE_TEMPLATE}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <CreateTemplateLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.EDIT_TEMPLATE}
      path={ROUTES.EDIT_TEMPLATE}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <CreateTemplateLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.DUPLICATE_TEMPLATE}
      path={ROUTES.DUPLICATE_TEMPLATE}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <CreateTemplateLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.TEMPLATES}
      path={ROUTES.TEMPLATES}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <TemplatesLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={`${ROUTES.SETTINGS}/*`}
      path={`${ROUTES.SETTINGS}/*`}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <SettingsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.SCHEDULED_BROADCASTS}
      path={ROUTES.SCHEDULED_BROADCASTS}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <ScheduledBroadcastsLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.TEAM_MANAGEMENT_V1}
      path={ROUTES.TEAM_MANAGEMENT_V1}
      element={
        <ProtectedRoute
          component={TeamManagementV1LazyComponent}
          hasAccess={permissions.canReadMembers.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.TEAM_MANAGEMENT}
      path={ROUTES.TEAM_MANAGEMENT}
      element={
        <ProtectedRoute
          component={TeamManagementLazyComponent}
          hasAccess={permissions.canReadMembers.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.TEAM_MANAGEMENT_INVITES}
      path={ROUTES.TEAM_MANAGEMENT_INVITES}
      element={
        <ProtectedRoute
          component={TeamManagementInvitesLazyComponent}
          hasAccess={permissions.canReadMembers.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.CUSTOMERS}
      path={ROUTES.CUSTOMERS}
      element={
        <ProtectedRoute
          component={CustomersLazyComponent}
          hasAccess={permissions.canGetCustomers.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.ANALYTICS}
      path={ROUTES.ANALYTICS}
      element={
        <ProtectedRoute
          component={AnalyticsLazyComponent}
          hasAccess={permissions.canGetAnalyticsWidgets.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.REPORTS}
      path={ROUTES.REPORTS}
      element={
        <ProtectedRoute
          component={ReportsLazyComponent}
          hasAccess={permissions.canGetReports.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.SEND_TEST_BROADCAST}
      path={ROUTES.SEND_TEST_BROADCAST}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <SendTestBroadcastLazyComponent />
        </Suspense>
      }
    />,
    <Route
      key={ROUTES.REQUESTS}
      path={ROUTES.REQUESTS}
      element={
        <ProtectedRoute
          component={RequestsLazyComponent}
          hasAccess={permissions.canManagePrivacyBotAccess.hasAccess}
        />
      }
    />,
    <Route
      key={ROUTES.CHAT_GPT}
      path={ROUTES.CHAT_GPT}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <ChatGptLazyComponent />
        </Suspense>
      }
    />,
    // <Route
    //   key={ROUTES.EMBEDDED_SIGNUP}
    //   path={ROUTES.EMBEDDED_SIGNUP}
    //   element={
    //     <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
    //       <EmbeddedSignupLazyComponent solutionID="" />
    //     </Suspense>
    //   }
    // />,
    // <Route
    //   key={ROUTES.EMBEDDED_SIGNUP_GUPSHUP}
    //   path={ROUTES.EMBEDDED_SIGNUP_GUPSHUP}
    //   element={
    //     <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
    //       <EmbeddedSignupLazyComponent solutionID="329012773225975" />
    //     </Suspense>
    //   }
    // />,
    // <Route
    //   key={ROUTES.EMBEDDED_SIGNUP_EXOTEL}
    //   path={ROUTES.EMBEDDED_SIGNUP_EXOTEL}
    //   element={
    //     <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
    //       <EmbeddedSignupLazyComponent solutionID="1017492475993062" />
    //     </Suspense>
    //   }
    // />,
    // <Route
    //   key={ROUTES.EMBEDDED_SIGNUP_VALUE_FIRST}
    //   path={ROUTES.EMBEDDED_SIGNUP_VALUE_FIRST}
    //   element={
    //     <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
    //       <EmbeddedSignupLazyComponent solutionID="206535115864975" />
    //     </Suspense>
    //   }
    // />,
    <Route
      key={ROUTES.DASHBOARD}
      path={ROUTES.DASHBOARD}
      element={
        <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
          <DashboardLazyComponent />
        </Suspense>
      }
    />,
  ];

  if (routes.length) {
    routes.push(
      <Route key="*" path="*" element={<Navigate to={'/'} replace />} />
    );
  }

  return routes;
}
